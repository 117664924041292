import store from '@/store'
export function getPermission(val){
  let flag = false;
  const permissionList = store.state.userInfo.content.permissionsList;
  try {
    permissionList.forEach(item => {
      if(item == val){
        flag = true
        throw Error()
      }
    });
  } catch (error) {
  }
  return flag
}