import axios from 'axios';
import Message from './message';
import router from '../router/index';

const instance = axios.create({
  baseURL: process.env.NODE_ENV == 'development' ? '/api' : process.env.VUE_APP_BASE_URL,
  timeout: 20000,
  headers: {'Content-Type': 'application/json'}
});
let messageFlag = true;
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token;
  return config;
}, (error) => {
  return Promise.reject(error);
});
instance.interceptors.response.use((res)=>{
  if(res.data.code === 200){
    messageFlag = true;
    return res;
  }else if(res.data.code === 401 ){
    if(messageFlag){
      messageFlag = false;
      Message.error(res.data.info);
    }
    localStorage.removeItem('userContent');
    localStorage.removeItem('token');
    router.push({name: 'login', params: { flag: true } });
    return Promise.reject();
  } else {
    Message.error(res.data.info);
    return Promise.reject();
  }
},(error) => {
  if(error.message.includes('timeout')){
    Message.error("请求超时");
  } else {
    Message.error("服务器出错了,请稍后重试");
  }
  return Promise.reject(error);
})
export default {
  all: function (option) {
    instance.defaults.headers.common['Authorization'] = localStorage.getItem("token");
    let arr = [], keys = [];
    for (let key in option) {
      keys.push(key);
      arr.push(option[key]);
    }
    return axios.all(arr).then(
      axios.spread(function () {
        let result = {};
        for (let i = 0; i < arguments.length; i++) {
          let item = arguments[i];
          if (item) {
            if (item.data && item.data.data) {
              result[keys[i]] = item.data.data;
            } else {
              result[keys[i]] = item;
            }
          }
        }
        return result;
      })
    );
  },
  get:function (url, params = {}){
    return new Promise((resolve,reject) => {
      instance.get(url, {params: params}).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error)
      })
    })
  },
  post:function (url, data = {}){
    return new Promise((resolve,reject) => {
      instance.post(url, data).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error)
      })
    })
  }
}