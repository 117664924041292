import Vue from 'vue'
import VueRouter from 'vue-router'
import {routers} from './routers'
import store from '../store'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: routers
})
router.beforeEach((to, from, next) => {
  if(to.name == 'home') {
    store.commit('navIsShow',false);
  }else{
    store.commit('navIsShow',true);
  }
  if(!localStorage.getItem("token") && to.name == 'login') {
    Vue.prototype.$notify.closeAll();
  }
  if( to.path == "/" || (!localStorage.getItem("token") && to.name !== 'login')){
    next({ name: 'login'});
  } else {
    next()
  }
})
export default router