<template>
  <el-input class="search" v-model="searchValue" :style="{width: width+'px'}" @change="name" clearable :placeholder="placeholder">
    <el-button slot="append" :style="{color}" icon="el-icon-search" @click="handleChange">搜索</el-button>
  </el-input>
</template>

<script>
export default {
  props:{
    width:{
      default: 260
    },
    nameValue:{
      default:''
    },
    color:{
      default:'#fff'
    },
    placeholder:{
      typeof:String,
      default:''
    }
  },
  data() {
    return {
      searchValue:this.nameValue
    }
  },
  watch:{
    nameValue(newValue,oldValue) {
      if(newValue == '' || newValue == null){
        this.searchValue = '';
      }else{
        this.searchValue = this.nameValue;
      }
    }
  },
  methods:{
    handleChange() {
      this.$emit('search',this.searchValue);
    },
    name(val) {
      if(val == '' || !val) {
        this.$emit('search',this.searchValue);
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .search{
    /deep/.el-input__inner{
      border-right: none;
    }
    /deep/.el-input-group__append{
      width:65px;
      height: 32px;
      box-sizing: border-box;
      padding: 0 0px;
      margin: 0;
      border: none;
      .el-button{
        width: 100%;
        height: 32px;
        margin: 0;
        border: none;
        color: #FFFFFF;
        background: #5CA4C9;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
  }
</style>