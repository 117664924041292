import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import echarts from 'echarts';
import "echarts-gl";
import '../theme/index.css';
import ajax from './utils/axios';
import dictionary from '@/utils/ReturnData';
import {getPermission} from '@/utils/getPermission';
import searchBtn from '@/components/search'

Vue.component('searchBtn', searchBtn)

Vue.prototype.$url = process.env.VUE_APP_BASE_URL;
Vue.prototype.$get = ajax.get;
Vue.prototype.$post = ajax.post;
Vue.prototype.$all = ajax.all;
Vue.config.productionTip = false;
Vue.prototype.$permission = getPermission;

Vue.use(ElementUI, { size: 'medium' });
Vue.use(dictionary);

new Vue({
  router,
  store,
  beforeCreate(){
    Vue.prototype.$bus = this //安装 全局事件总线 this指向vue原型
  },
  render: h => h(App)
}).$mount('#app')
