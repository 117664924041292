export const loginRouter = {
  path: "/login",
  name: "login",
  component: () => import("@/views/login.vue"),
};
// 用户
export const environmentaRouter = {
  path: "/environmenta",
  name: "environmenta",
  component: () => import("@/views/navMenu.vue"),
  children: [
    {
      path: "home",
      name: "home",
      code: "999",
      meta: { name: "项目概况", icon: "icon-xiangmugaikuang" },
      component: () => import("@/views/user/home/index.vue"),
    },
    {
      path: "thermostat-manage",
      name: "thermostat-manage",
      code: "101",
      meta: { name: "风盘温控管理", icon: "icon-fengpan" },
      component: () => import("@/views/user/thermostat-manage/index.vue"),
      children: [
        {
          path: "set",
          name: "set",
          code: "101_201",
          meta: { name: "风盘温控器设置" },
          component: () => import("@/views/user/thermostat-manage/set.vue"),
        },
        {
          path: "marshalling",
          name: "marshalling",
          code: "101_202",
          meta: { name: "风盘温控器编组" },
          component: () =>
            import("@/views/user/thermostat-manage/marshalling.vue"),
        },
        {
          path: "thermostat",
          name: "thermostat",
          code: "101_203",
          meta: { name: "风盘温控策略" },
          component: () =>
            import("@/views/user/thermostat-manage/thermostat.vue"),
        },
      ],
    },
    {
      path: "VRV/manage",
      name: "VRVThermostat-manage",
      code: "102",
      meta: { name: "VRV温控管理", icon: "icon-VRV" },
      component: () => import("@/views/user/VRV/index.vue"),
      children: [
        {
          path: "VRV/set",
          name: "VRVSet",
          code: "102_201",
          meta: { name: "VRV温控器设置" },
          component: () => import("@/views/user/VRV/set.vue"),
        },
        {
          path: "VRV/marshalling",
          name: "VRVMarshalling",
          code: "102_202",
          meta: { name: "VRV温控器编组" },
          component: () => import("@/views/user/VRV/marshalling.vue"),
        },
        {
          path: "VRV/thermostat",
          name: "VRVThermostat",
          code: "102_203",
          meta: { name: "VRV温控策略" },
          component: () => import("@/views/user/VRV/thermostat.vue"),
        },
      ],
    },
    {
      path: "electricity-statistics",
      name: "electricity-statistics",
      code: "103",
      meta: { name: "电耗统计分析", icon: "icon-dianhaotongji" },
      component: () =>
        import("@/views/user/electricity-statistics/statistics.vue"),
    },
    {
      path: "alert",
      name: "alert",
      code: "104",
      meta: { name: "告警管理", icon: "icon-gaojing1" },
      component: () => import("@/views/user/alert/alert.vue"),
    },
    {
      path: "ledger",
      code: "105",
      meta: { name: "台账管理", icon: "icon-taizhang" },
      component: () => import("@/views/user/ledger/index.vue"),
      children: [
        {
          path: "deviceLedger",
          name: "deviceLedger",
          code: "105_201",
          meta: { name: "设备台账" },
          component: () => import("@/views/user/ledger/deviceLedger.vue"),
        },
        {
          path: "building",
          name: "building",
          code: "105_202",
          meta: { name: "建筑台账", icon: "icon-gaojing1" },
          component: () => import("@/views/user/ledger/building.vue"),
        },
        {
          path: "room",
          name: "room",
          code: "105_203",
          meta: { name: "房间台账", icon: "icon-gaojing1" },
          component: () => import("@/views/user/ledger/room.vue"),
        },
      ],
    },
    {
      path: "user-config",
      code: "106",
      meta: { name: "管理配置", icon: "icon-canshu1" },
      component: () => import("@/views/user/user-config/index.vue"),
      children: [
        {
          path: "userRole",
          name: "userRole",
          code: "106_201",
          meta: { name: "角色" },
          component: () => import("@/views/user/user-config/role.vue"),
        },
        {
          path: "user",
          name: "user",
          code: "106_202",
          meta: { name: "用户" },
          component: () => import("@/views/user/user-config/user.vue"),
        },
        {
          path: "global",
          name: "global",
          code: "106_203",
          meta: { name: "全局配置" },
          component: () => import("@/views/user/user-config/global.vue"),
        },
        {
          path: "caveat",
          name: "caveat",
          code: "106_204",
          meta: { name: "告警设置" },
          component: () => import("@/views/user/user-config/caveat.vue"),
        },
        {
          path: "config-phone",
          name: "configPhone",
          code: "106_205",
          meta: { name: "告警电话接听" },
          component: () => import("@/views/user/user-config/configPhone.vue"),
        },
        {
          path: "protection",
          name: "protection",
          code: "106_206",
          meta: { name: "防护策略" },
          component: () => import("@/views/user/user-config/protection.vue"),
        },
      ],
    },
    {
      path: "data-summarizing",
      name: "environmenta/data-summarizing",
      code: "107",
      meta: { name: "数据汇总", icon: "icon-shujubaobiao" },
      component: () => import("@/views/user/data-summarizing/index.vue"),
    },
  ],
};

export const routers = [loginRouter, environmentaRouter];
