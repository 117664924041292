import Vue from 'vue';
import Vuex from 'vuex';
import { environmentaRouter } from '@/router/routers';
import router from '@/router/index';
import Util from '@/utils/index';

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    alertPopData:null,
    menuList:[],
    timerNum:localStorage.getItem("timerNum") ? localStorage.getItem("timerNum") : null,
    navIsShow:true,
    userInfo:{
      token:localStorage.getItem('token') ? localStorage.getItem('token') : '',
      content:localStorage.getItem('userContent') ? JSON.parse(localStorage.getItem('userContent')) : ''
    }
  },
  mutations: {
    alertData(state,data) {
      state.alertPopData = data
    },
    localUserInfo(state,data) {
      localStorage.setItem('token',data.Authorization);
      localStorage.setItem('userContent',JSON.stringify(data.result));
      state.userInfo.token = data.Authorization;
      state.userInfo.content = data.result;
    },
    //账号剩余时间
    timer:(state, data) => {
      localStorage.setItem("timerNum", data);
      state.timerNum = localStorage.getItem("timerNum")
    },
    navIsShow(state,data) {
      state.navIsShow = data;
    },
    // 登录成功 获取用户权限路由列表
		updateMenulist (state) {
      let accessCode = state.userInfo.content.permissionsList ? state.userInfo.content.permissionsList : [];
      if(accessCode && accessCode.length != 0) {
        let menuList = [];
        environmentaRouter.children.forEach((item, index) => {
          if (Util.oneOf(item.code, accessCode)) {
            if(item.children){
              let len = menuList.push(item);
              let childrenArr = item.children.filter(child => {
                if (Util.oneOf(child.code, accessCode)) {
                  return child;
                }
              });
              menuList[len - 1].children = childrenArr;
            } else {
              menuList.push(item);
            }
          }
        });
        state.menuList = menuList;
      }
    },
  },
  actions: {
    
  }
})
export default store